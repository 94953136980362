import React from "react"
import { Section } from "../components/templates/Section"
import ButtonExternalLink from "../components/atoms/Button/buttonExternalLink"
import Layout from "../components/templates/layout"
import VectorGraphic from "../components/atoms/vectorGraphic"

const Canceled = ({ location }) => {
  return (
    <Layout location={location} hideFooter hideNavigation>
      <>
        <Section className="bg-pink-lighter pb-12">
          <div className="col-span-6 md:col-start-2 md:col-end-6 xl:col-start-4 xl:col-end-11 text-center">
            <h1 className="font-serif text-center text-5xl mb-4">
              Payment Cancelled
            </h1>
            <p className="text-center mb-0">
              It looks like you have cancelled your payment, would you like to{" "}
              <b>retry?</b>
            </p>
            <p className="text-center mb-6">
              Click on the link within your <b>email</b>
            </p>

            <ButtonExternalLink
              href="https://www.guarantid.com"
              label="Back to home"
              size="large"
              className="m-auto"
            />
          </div>
        </Section>
        <Section>
          <div className="col-span-6 xl:col-start-5 xl:col-end-10 flex justify-center -mt-36.25">
            <VectorGraphic name="cancel" />
          </div>
        </Section>
      </>
    </Layout>
  )
}

export default Canceled
